import { React, useState, useEffect } from 'react'
import { Toast,Selector } from 'antd-mobile'
import './withdraw.css'
import _ from 'lodash'
import { getInfo, withdraw } from '../request/api'
import { useNavigate } from "react-router-dom";
import { LeftOutline } from 'antd-mobile-icons'
import { useTranslation } from "react-i18next";

export default function Withdraw() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [orderMoney, setOrderMoney] = useState()
    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('0.00');//实际到账
    const [inputValue3, setInputValue3] = useState('');
    const [disableds, setDisableds] = useState(false);
    const [options, setOptions] = useState([]);
    const [pay_type, setPay_type] = useState([]);
    const [ispay_type, setIspay_type] = useState(false);
    const getData = async () => {
        Toast.show({
            icon: 'loading',
            content: t('t4'),
            duration: 800
        })
        const res = await getInfo({})
        if (res.code == 0) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('warning');
            localStorage.removeItem('tan');
            localStorage.removeItem('tabber');
            Toast.show({
                icon: 'fail',
                content: res.info,
            })
            setTimeout(() => {
                window.location.hash = "/login"
            }, 2500);
        }
        setData(res.data)
        if (res.data.uget_address_trc == '') {
            Toast.show({
                content: t('t10'),
                duration: 2200
            })
            setTimeout(() => {
                navigate('/UserInfo')
            }, 2200);
        }
        if (res.data.freeze_gec !== '0.00') {
            //有未完成订单，余额减去订冻结的金额
            setOrderMoney((Number(res.data.balances) - Number(res.data.freeze_gec)).toFixed(2))
        } else {
            //正常余额
            setOrderMoney(Number(res.data.balances));
        }
        let payList = [{
            label: 'TRC-20',
            value: 1,
        }]
        if (res.data.has_erc == 1) {
            payList.push({
                label: 'ERC-20',
                value: 2,
            })
            setIspay_type(true)
        }
        if (res.data.has_btc == 1) {
            payList.push({
                label: 'BTC',
                value: 3,
            })
            setIspay_type(true)
        }
        setOptions(payList)
    }
    const balance = (e) => {
        if (orderMoney < 0) {
            setDisableds(true)
        } else if (orderMoney - e.target.value < 0) {
            setInputValue1(orderMoney)
            setInputValue2((Number(orderMoney) - Number(orderMoney) * Number(data.t_rate / 100)).toFixed(2))
        } else {
            setInputValue1(e.target.value)
            setInputValue2((Number(e.target.value) - Number(e.target.value) * Number(data.t_rate / 100)).toFixed(2))
        }
    }
    const submit = () => {
        // console.log(data);
        if (inputValue3.length < 6 || inputValue3.length > 16) {
            Toast.show({
                content: t('t15'),
                duration: 2200
            })
        } else {
            Toast.show({
                icon: 'loading',
                content: t('t4'),
                duration: 800
            })
            withdraw({
                gpay_trc_address: data.uget_address_trc,
                amount: inputValue1,
                real_amount: inputValue2,
                password: inputValue3,
                rate: data.rate,
                pay_type: pay_type
            }).then(res => {
                if (res.code == 0) {
                    Toast.show({
                        icon: 'fail',
                        content: res.info,
                        duration: 2200
                    })
                    return false
                }
                Toast.show({
                    icon: 'success',
                    content: t('t16'),
                    duration: 1100
                })
                setTimeout(() => {
                    navigate('/')//提现记录页
                }, 1500)
            })
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='Withdraw'>
            <div className='header'>
                <div className='backIcon' onClick={() => { navigate('/User') }}>
                    <LeftOutline />
                </div>
                <p>{t('g1')}</p>
            </div>
            <div className='Withdraw_box'>
                {ispay_type && <div className='withdraw_from'>
                    <span>{t('new1')}</span>
                    <Selector className='n1'
                        options={options}
                        defaultValue={[1]}
                        onChange={(arr, extend) => setPay_type(arr[0])}
                    />
                </div>}
                <div className='withdraw_from'>
                    <span>{t('mx8')}</span>
                    <input type="text" disabled value={data.uget_address_trc || ''} />
                </div>
                <div className='withdraw_from'>
                    <div className='available'>
                        <span>{t('mx9')}</span>
                        <p style={{ color: '#ff9a00' }}>{orderMoney}</p>
                    </div>
                    <input type="text" disabled={disableds} value={inputValue1 || ''} onChange={(e) => { balance(e) }} placeholder={t('mx11')} />
                </div>
                <div className='withdraw_from'>
                    <span>{t('mx10')}</span>
                    <input type="text" disabled value={inputValue2 || ''} />
                </div>
                <div className='withdraw_from'>
                    <span>{t('l10')}</span>
                    <input type="password" onChange={(e) => setInputValue3(e.target.value)} placeholder={t('l16')} />
                </div>
                <div className='withdraw_button' onClick={submit}>
                    <p>{t('ty3')}</p>
                </div>
            </div>
        </div>
    )
}
