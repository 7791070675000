import axios from 'axios';
import QS from 'qs';
import { Toast } from 'antd-mobile'
import { basciUrl } from './defaultURL'
import { useTranslation } from "react-i18next";

const request = axios.create({
    baseURL: basciUrl,
    retry: 0,
})

// 请求拦截器
request.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.data.token = token
    }
    if (!localStorage.getItem('lang')) {
        config.data.lang = 'en-us'
    } else {
        config.data.lang = (localStorage.getItem('lang') == 'zh' ? 'zh-cn' : (localStorage.getItem('lang') == 'en' ? 'en-us' : (localStorage.getItem('lang') == 'es' ? 'es-es' : (localStorage.getItem('lang') == 'pl' ? 'pl-PL' : (localStorage.getItem('lang') == 'cs' ? 'cs-CZ' : (localStorage.getItem('lang') == 'da' ? 'da-DK' : (localStorage.getItem('lang') == 'el' ? 'el-GR' : (localStorage.getItem('lang') == 'ro' ? 'ro-RO' : (localStorage.getItem('lang') == 'sk' ? 'sk-SK' : (localStorage.getItem('lang') == 'de' ? 'de-DE' : (localStorage.getItem('lang') == 'it' ? 'it-IT' : (localStorage.getItem('lang') == 'jp' ? 'ja-JP' : (localStorage.getItem('lang') == 'tr' ? 'tr-tr' : (localStorage.getItem('lang') == 'ar' ? 'ar-AE' : (localStorage.getItem('lang') == 'nl' ? 'nl-nl' : (localStorage.getItem('lang') == 'bn' ? 'bn-bn' : (localStorage.getItem('lang') == 'kr' ? 'kr-kr' : (localStorage.getItem('lang') == 'ru' ? 'ru-ru':(localStorage.getItem('lang') == 'fa' ?'fa-ir':'pt-pt')))))))))))))))))))
    }
    config.headers = {
        "Content-Type": "application/json",
    };
    config.data = JSON.stringify(config.data);

    return config
}, err => {
    return err;
})
const tostJump = (res) => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
    localStorage.removeItem('warning');
    localStorage.removeItem('tan');
    localStorage.removeItem('tabber');
    Toast.show({
        icon: 'fail',
        content: res.data.info,
    })
    setTimeout(() => {
        window.location.hash = "/login"
    }, 2500);
}
// 响应拦截器
request.interceptors.response.use(
    res => {
        if (res.data.code == 1) {
            return res.data;
        } else if (res.data.code == 0) {
            return res.data;
        } else {
            switch (res.data.code) {
                case 401:
                    tostJump(res)
                    break;
                case 404:
                    tostJump(res)
                    break;
                case 302:
                    tostJump(res)
                    break;
                case 101:
                    return res.data;
                case 102:
                    return res.data;
                case 103:
                    return res.data;
                case 500:
                    tostJump(res)
                    break;
            }
        }

    },
    err => {
        const { t, i18n } = useTranslation();
        console.log(err);
        let status = err.response.data ? err.response.status : 0
        let code = status
        switch (code) {
            case 400:
                Toast.show({
                    icon: 'fail',
                    content: t('t1'),
                })
                setTimeout(() => {
                    window.location.hash = "/login"
                }, 1500);
                break;
            case 401:
                localStorage.removeItem('userInfo');
                localStorage.removeItem('token');
                localStorage.removeItem('warning');
                localStorage.removeItem('tan');
                localStorage.removeItem('tabber');
                Toast.show({
                    icon: 'fail',
                    content: t('t1'),
                })
                setTimeout(() => {
                    window.location.hash = "/login"
                }, 1500);
                break;
            case 500:
                return false;
        }
    });
export default request