//韩文
export const kr = {
    //탐색 바
    'n1': '집',
    'n2': '순서',
    'n3': '거래',
    'n4': '팀',
    'n5': '내',
    //프롬프트 클래스
    't1': '오류가 발생했습니다',
    't2': '복사 성공',
    't3': '정보를 완전히 입력해 주세요',
    't4': '로드 중...',
    't5': '로그인 성공',
    't6': '제출하려면 점수가 3보다 커야 합니다.',
    't7': '제출 성공',
    't8': '잔고가 부족하여 받을 수 없습니다',
    't9': '제출 실패',
    't10': '먼저 주소를 바인딩해 주시면 개인센터 페이지로 이동됩니다',
    't11': '주소가 바인딩되었습니다. 수정하려면 고객 서비스에 문의하세요.',
    't12': '수정 성공',
    't13': '수정 실패',
    't14': '비밀번호를 변경하려면 고객 서비스에 문의하세요',
    't15': '비밀번호 형식 오류',
    't16': '출금 성공',
    't17': '최대 인출',
    't18': '최소 출금',
    't19': '두 비밀번호가 일치하지 않습니다',
    't20': '프롬프트',
    't21': '일시적으로 점프할 수 없습니다',
    't22': '비밀번호 형식 오류',
    't23': '등록 성공',
    't24': '지역번호를 입력하세요',
    't25': '더 이상',
    //가입하기
    'l1': '언어',
    'l2': '계좌번호를 입력하세요',
    'l3': '비밀번호를 입력하세요',
    'l4': '기억하다',
    'l5': '로그인',
    'l6': '등록으로 이동',
    'l7': '계정 없음',
    'l8': '휴대폰번호',
    'l9': '닉네임',
    'l10': '비밀번호',
    'l11': '비밀번호 확인',
    'l12': '초대 코드',
    'l13': '등록',
    'l14': '이미 계정이 있습니다',
    'l15': '로그인으로 이동',
    'l16': '6~16자리 비밀번호를 입력하세요',

    //돕다
    'bz1': '도움말',
    'bz2': '자주 묻는 질문',
    'bz3': '거래 규칙',
    'bz4': '돈을 인출하는 방법',
    'bz5': '충전 방법',
    'bz6': '1.ebay Review RoBoT 그것은 무엇입니까? ',
    'bz7': '리뷰로봇(ebay Review RoBoT)은 전 세계 리뷰로봇 온라인 스토어 판매자들이 더 많은 주문 매출을 얻고 리뷰로봇 온라인 스토어의 검색 데이터를 늘릴 수 있도록 돕는 마케팅 및 프로모션 회사입니다. 우리는 ebay Review RoBoT, 판매자 및 소비자 간의 3자 수익성 있는 마케팅 및 프로모션 모델을 구축하기 위해 최선을 다하고 있습니다. 최신 P2P 블록체인 기술이 결합되어 USDT(TRC20, ERC20)를 통해 소비자와 가맹점을 빠르게 연결합니다. 등록된 사용자는 주문에 대한 수수료를 받을 수 있고 판매자는 매장의 판매 데이터를 늘릴 수 있습니다. 인터넷 블록체인 모델의 최신 수익 모델입니다! ',
    'bz8': '2.ebay Review RoBoT은 어떻게 작동하나요?',
    'bz9': 'ebay Review RoBoT의 일일 피드백을 보면 개선이 필요한 제품이 ebay Review RoBoT에서 판매되고 있음을 알 수 있습니다. 사용자는 쉽게 주문을 클릭하기만 하면 시스템이 자동으로 주문 구독을 생성합니다. 사용자는 블록체인 USDT를 통해 주문 금액을 지불하고 일일 커미션을 받습니다. ',
    'bz10': '3. 두 거래에서 동일한 통화의 가격이 왜 다른가요? ',
    'bz11': '가격 차이는 통화 전송 속도, 네트워크 상태, 통화 접근 제한, 다른 지역 사람들의 통화 인식, 심지어 거래 쌍을 포함하여 통화의 자유로운 유통에 도움이 되지 않는 모든 요인으로 인해 발생합니다. 거래소 종류, 거래 등을 통해 제공됩니다. 따라서 동일한 통화라도 거래마다 가격 차이가 발생할 수 있습니다. ',
    'bz12': '4. 투자이익? ',
    'bz13': '상품 가격이 높을수록 주문 수익도 높아집니다. 동시에 ebay Review RoBoT는 대규모 커미션 주문을 무작위로 배포합니다. ',
    'bz14': '매일 시스템은 자동으로 60개의 제품 주문을 생성하여 사용자에게 배포합니다. 사용자는 USDT를 통해 각 주문에 대한 결제를 완료하고 0.6%의 수수료를 받습니다. 대규모 커미션 주문은 무작위로 할당됩니다. ',
    'bz15': 'USDT는 60개 주문 후에 출금될 수 있습니다. (60개 주문이 완료되지 않을 경우 당일 남은 주문까지 시스템이 자동으로 정지됩니다.)',
    'bz16': '각 계정은 하나의 USDT 계정에만 바인딩할 수 있습니다. 오류가 발생하면 고객 서비스에 문의하세요.',
    'bz17': '일일 주문 배송 완료 후 정상적으로 출금이 가능합니다. 출금 시작 후 24시간 이내에 도착하며 출금 한도가 없습니다.',
    'bz18': '충전하기 전에 출금 주소(TRC-20 지원) USDT 주소를 바인딩하세요. 앱이나 웹 페이지에서 충전을 클릭하고 충전에 사용할 블록체인(TRC-20)을 선택할 수 있습니다. ',
    'bz19': "관련 계정간 상호충전, 계좌이체, 환전이체 등의 행위를 금지하며, 해당 행위가 적발될 경우 해당 계정을 차단합니다.",
    //모듈 텍스트
    //일반적인 단어
    'ty1': '알았어',
    'ty2': '취소',
    'ty3': '제출',
    'ty4': '주소',
    //첫 장
    's1': '누적 수입',
    's2': '서비스',
    's3': '회사 소개',
    's4': '도움말',
    's5': '파트너',
    's6': '내 서비스',
    //주문하다
    'd1': '미완성',
    'd2': '완료',
    'd3': '아직 데이터가 없습니다',
    'd4': '홀수',
    'd5': '거래 시간',
    'd6': '금액',
    'd7': '현재 작업 수',
    'd8': '예상 수익',
    'd9': '더 필요함',
    'd10': '평가',
    //거래
    'j1': '계정 잔액',
    'j2': '거래 소개',
    'j3': '리뷰 RoBoT는 매일 리뷰 RoBoT의 피드백을 사용하여 리뷰 RoBoT에서 판매하는 제품에 개선이 필요한 것을 보여줍니다. 사용자는 주문을 클릭하기만 하면 시스템이 자동으로 주문 서명을 생성합니다. 사용자는 USDT 블록체인을 통해 주문 금액을 지불하고 매번 0.6% 이상의 수수료를 받을 수 있으며, 시스템은 대규모 보상 주문을 무작위로 분배합니다. ',
    'j4': '일치 시작',
    'j5': '오늘의 수입',
    'j6': '완료된 번호',
    'j7': '총 작업 수',
    //팀
    'td1': '균형',
    'td2': '수수료',
    'td3': '수신',
    'td4': '총 인원수',
    'td5': '사용자',
    'td6': '기여',
    'td7': '수량',
    'td8': '상태',
    //개인센터
    'g1': '철회',
    'g2': '충전',
    'g3': '친구 초대',
    'g4': '지금 초대',
    'g5': '등록 후 친구가 얻는 모든 수익에 대해 해당 비율의 커미션을 받게 됩니다.',
    'g6': '개인정보',
    'g7': '펀드 세부정보',
    'g8': '충전 기록',
    'g9': '출금 기록',
    'g10': '언어',
    'g11': '로그아웃',
    'g12': '정말로 로그아웃하시겠습니까?',
    //사용자 정보
    'yh1': '사용자 정보',
    'yh2': '수정',
    'yh3': '설정',
    'yh4': '전화',
    'yh6': '원래 비밀번호',
    'yh7': '새 비밀번호',
    //세부
    'mx1': '커미션 수집',
    'mx2': '이익',
    'mx3': '철회 거부됨',
    'mx4': '시간',
    'mx5': '충전 성공',
    'mx6': '거부됨',
    'mx7': '보류 중',
    'mx8': '출금 주소',
    'mx9': '사용 가능한 잔액',
    'mx10': '실제 도착',
    'mx11': '출금 금액을 입력하세요',
    //충전
    'cz1': '입금 주소는 ERC20만 지원하며, 최소 입금액은 20USDT입니다.',
    'cz2': '복사',
    'cz3': '피아트 통화 선택',
    'cz4': '결제',
    'cz5': '입금 주소는 TRC20-USDT만 지원하며, 최소 입금액은 20USDT입니다.',
    'cz6': '입금 주소는 ERC20만 지원하며, 최소 입금액은 20USDT입니다.',
    'cz1': '입금 주소는 ERC20만 지원하며, 최소 입금액은 20USDT입니다.',
    'cz1': '입금 주소는 ERC20만 지원하며, 최소 입금액은 20USDT입니다.',
    "cz10": "입금 주소는 BTC만 지원합니다",
    //영역
    'qy1': '지역번호',
    'yq1': '친구 초대',
    'yq2': '초대 코드',
    'yq3': '친구를 초대하고 푸짐한 혜택을 누려보세요',
    'yq4': '가서 초대하세요',
    "new1":"결제 방법"
}