export const fa = {
    "n1": "صفحه اول",
    "n2": "سفارش",
    "n3": "تجارت",
    "n4": "تیم",
    "n5": "مال خودم",
    "t1": "خطا",
    "t2": "تکرار",
    "t3": "لطفاً اطلاعات را کامل کنید",
    "t4": "بارگذاری…",
    "t5": "با موفقیت فرود آمد",
    "t6": "برای ارسال امتیاز باید بیشتر از 3 باشد",
    "t7": "با موفقیت ثبت شد",
    "t8": "تعادل کافی ، امکان پذیر نیست",
    "t9": "تسلیم نشد",
    "t10": "لطفاً ابتدا آدرس را وصل کنید ، و صفحه مرکز شخصی در حال پرش است",
    "t11": "برای آدرس الزام آور ، برای اصلاح با خدمات مشتری تماس بگیرید",
    "t12": "با موفقیت اصلاح شد",
    "t13": "نتوانست ویرایش شود",
    "t14": "لطفاً برای تغییر رمز عبور با خدمات مشتری تماس بگیرید",
    "t15": "خطای قالب رمز عبور",
    "t16": "عقب نشینی موفقیت آمیز",
    "t17": "بیشتر عقب نشینی",
    "t18": "حداقل پول نقد",
    "t19": "دو رمز عبور متناقض هستند",
    "t20": "اشاره",
    "t21": "نمی توان به طور موقت پرش کرد",
    "t22": "خطای قالب رمز عبور",
    "t23": "موفقیت ثبت نام",
    "t24": "لطفا کد منطقه را وارد کنید",
    "t25": "بیشتر نه",
    "l1": "زبان",
    "l2": "لطفا نام کاربری را وارد کنید",
    "l3": "لطفا رمز عبور را وارد کنید",
    "l4": "یاد آوردن",
    "l5": "وارد شدن",
    "l6": "ثبت نام",
    "l7": "بدون حساب",
    "l8": "شماره تلفن",
    "l9": "نام مستعار",
    "l10": "کلمه عبور",
    "l11": "تأیید رمز عبور",
    "l12": "کد دعوت",
    "l13": "ثبت نام",
    "l14": "حساب موجود",
    "l15": "وارد شدن",
    "l16": "لطفاً رمز ورود دیجیتال 6-16 رقمی را وارد کنید",
    "bz1": "کمک",
    "bz2": "مشکل مشترک",
    "bz3": "قوانین تجارت",
    "bz4": "نحوه برداشت پول",
    "bz5": "نحوه شارژ مجدد",
    "bz6": "1. ربات بررسی Teebay چیست؟",
    "bz7": "EBAY Review Robot یک شرکت بازاریابی است که به Ebay کمک می کند تا بازرگانان فروشگاه اینترنتی روبات را از سراسر جهان بررسی کنند تا بتوانند سفارشات بیشتری را به دست آورند و داده های مشاهده را از فروشگاه آنلاین Robot Review EBAY افزایش دهند. ما متعهد به ایجاد یک مدل بازاریابی سود سه حزب بین ربات بررسی eBay و بازرگانان و مصرف کنندگان هستیم. با ترکیب جدیدترین فناوری Blockchain P2P ، به سرعت مصرف کنندگان و بازرگانان را از طریق USDT (TRC20 ، ERC20) به هم وصل می کند. کاربران ثبت نام شده می توانند کمیسیون سفارشات را دریافت کنند ، در حالی که بازرگانان می توانند داده های فروش فروشگاه را افزایش دهند. این آخرین مدل سود در مدل blockchain اینترنت است!",
    "bz8": "2. چگونه ربات بررسی Forebay را کار کنیم؟",
    "bz9": "از طریق بازخورد روزانه ربات بررسی eBay ، محصولاتی که باید در فروش روبات بررسی eBay بهبود یابد ، کاربران فقط باید به راحتی روی سفارش کلیک کنند تا به طور خودکار اشتراک های سفارش در سیستم ایجاد کنند. کاربران مبلغ سفارش را از طریق blockchain USDT پرداخت می کنند و کمیسیون های روزانه دریافت می کنند.",
    "bz10": "3. چرا در دو معاملات تفاوت در قیمت همان ارز وجود دارد؟",
    "bz11": "اختلاف قیمت توسط همه عواملی که منجر به آزادی ارز نمی شوند ، از جمله سرعت انتقال ارز ، شرایط شبکه ، محدودیت های دسترسی به ارز ، افراد در مناطق مختلف و حتی انواع معاملات و معاملات ارائه شده توسط مبادله ایجاد می شود. بنابراین ، همان ارز ممکن است باعث اختلاف قیمت در معاملات مختلف شود.",
    "bz12": "4. سود سرمایه گذاری؟",
    "bz13": "هرچه قیمت شما بالاتر باشد ، سود شما نیز بیشتر می شود. در عین حال ، ربات بررسی eBay به طور تصادفی دستورات بزرگ کمیسیون را اختصاص می دهد.",
    "bz14": "هر روز ، سیستم به طور خودکار 60 سفارش کالا را به کاربر تولید و توزیع می کند. سفارشات کمیسیون بزرگ به طور تصادفی توزیع می شود.",
    "bz15": "USDT را می توان پس از 60 سفارش خارج کرد. (اگر 60 سفارش به پایان نرسد ، سیستم به طور خودکار متوقف می شود تا مبلغ سفارش مازاد روز)",
    "bz16": "هر حساب فقط در صورت بروز خطایی می تواند به یک حساب USDT محدود شود.",
    "bz17": "پس از اتمام تحویل روزانه ، می توانید پس از شروع برداشت ، به طور عادی پس بگیرید.",
    "bz18": "قبل از شارژ ، لطفاً آدرس خروج خود را (پشتیبانی از TRC-20) آدرس USDT وصل کنید.",
    'bz19': "انجام شارژهای متقابل، نقل و انتقالات بانکی، انتقال وجه و غیره بین حساب های مرتبط ممنوع است.",
    "ty1": "مطمئن",
    "ty2": "لغو کردن",
    "ty3": "ارسال",
    "ty4": "نشانی",
    "s1": "درآمد تجمعی",
    "s2": "خدمت",
    "s3": "درباره ما",
    "s4": "کمک",
    "s5": "شریک",
    "s6": "خدمات من",
    "d1": "انجام نشده",
    "d2": "کامل",
    "d3": "اطلاعاتی وجود ندارد",
    "d4": "یک شماره",
    "d5": "ساعت معامله",
    "d6": "میزان",
    "d7": "شماره کار فعلی",
    "d8": "بازده مورد انتظار",
    "d9": "هنوز",
    "d10": "نمره",
    "j1": "موجودی حساب",
    "j2": "معرفی معامله",
    "j3": "EBAY Review Robot هر روز از بازخورد ربات Ebay Review استفاده می کند تا محصولاتی را که باید توسط فروش روبات Ebay Review بهبود یابد ، نمایش دهد. اگر کاربر فقط نیاز به کلیک بر روی سفارش داشته باشد ، سیستم به طور خودکار امضای سفارش را تولید می کند. کاربر مبلغ سفارش را از طریق blockchain USD پرداخت می کند و می تواند هر بار بیش از 0.6 ٪ کمیسیون را دریافت کند و سیستم به طور تصادفی سفارشات پاداش بزرگ را توزیع می کند.",
    "j4": "شروع تطبیق",
    "j5": "مزایای امروز",
    "j6": "شماره تکمیل شده",
    "j7": "وظیفه کل",
    "td1": "تعادل",
    "td2": "راهق",
    "td3": "دريافت كردن",
    "td4": "کل مردم",
    "td5": "کاربر",
    "td6": "مشارکت",
    "td7": "تعداد",
    "td8": "حالت",
    "g1": "کنار کشیدن",
    "g2": "بالا بردن",
    "g3": "دوستان را دعوت کنید",
    "g4": "اکنون دعوت کنید",
    "g5": "برای هر سود حاصل از دوستان ، کمیسیون نسبت مربوطه را دریافت خواهید کرد",
    "g6": "اطلاعات شخصی",
    "g7": "جزئیات سرمایه",
    "g8": "رکورد شارژ",
    "g9": "رکورد برداشت",
    "g10": "زبان",
    "g11": "خروج از سیستم",
    "g12": "آیا مطمئن هستید که خروج و ورود به سیستم دارید؟",
    "yh1": "اطلاعات کاربر",
    "yh2": "تجدید نظر کردن",
    "yh3": "برپایی",
    "yh4": "تلفن",
    "yh6": "رمز عبور قدیمی",
    "yh7": "رمز عبور جدید",
    "mx1": "مجموعه",
    "mx2": "درآمد",
    "mx3": "کنار کشیدن",
    "mx4": "زمان",
    "mx5": "شارژ مجدد",
    "mx6": "رد شده",
    "mx7": "انتظار",
    "mx8": "آدرس عقب نشینی",
    "mx9": "تعادل موجود",
    "mx10": "در حقیقت",
    "mx11": "لطفاً مبلغ برداشت را وارد کنید",
    "cz1": "آدرس سپرده فقط از ERC20 پشتیبانی می کند ، حداقل مبلغ سپرده 20USDT است",
    "cz2": "کپی 🀄",
    "cz3": "انتخاب ارز قانونی",
    "cz4": "پرداخت",
    "cz5": "آدرس سپرده فقط از TRC20-USDT پشتیبانی می کند ، و حداقل مبلغ سپرده 20USDT است",
    "cz6": "آدرس سپرده فقط از ERC20 پشتیبانی می کند ، حداقل مبلغ سپرده 20USDT است",
    "cz10": "آدرس سپرده فقط از BTC پشتیبانی می کند",
    "qy1": "رمز منطقه",
    "yq1": "دوستان را دعوت کنید",
    "yq2": "کد دعوت",
    "yq3": "از دوستان دعوت کنید تا از درآمد زیادی برخوردار شوند",
    "yq4": "بیا دیگه",
    "new1": "روش پرداخت"
  }
  