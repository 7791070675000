import { React, useState, useEffect } from 'react'
import '../views/login.css'
import { DownOutline } from 'antd-mobile-icons'
import { Checkbox, Picker, Button, Toast } from 'antd-mobile'
import { useNavigate, useLocation } from "react-router-dom";
import { login } from '../request/api'
import { useTranslation } from "react-i18next";

export default function Login() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    const [areaNum, setAreaNum] = useState(1)
    const [visible, setVisible] = useState(false)//选择器显示
    const [input1Value, setInput1Value] = useState('');
    const [input2Value, setInput2Value] = useState('');
    useEffect(() => {
        if (location.state != null) {
            setAreaNum(location.state)
        } else {
            setAreaNum(1)
        }
    }, [])
    const [list1, setlist1] = useState([
        [
            { label: '中文', value: 'zh' },
            { label: 'English', value: 'en' },
            { label: 'فارسی', value: 'fa' },
            { label: 'Español', value: 'es' },
            { label: 'Português', value: 'pt' },
            { label: 'Polski', value: 'pl' },
            { label: 'čeština', value: 'cs' },
            { label: 'Dansk', value: 'da' },
            { label: 'Ελληνικά', value: 'el' },
            { label: 'Română', value: 'ro' },
            { label: 'Slovenský', value: 'sk' },
            { label: 'Deutsch', value: 'de' },
            { label: 'Italiano', value: 'it' },
            { label: 'やまと', value: 'jp' },
            { label: 'Türkçe', value: 'tr' },
            { label: 'العربية', value: 'ar' },
            { label: 'Nederlands', value: 'nl' },
            { label: 'বাংল', value: 'bn' },
            { label: '한국인', value: 'kr' },
            { label: 'русский язык', value: 'ru' },
        ],
    ])
    const submit = () => {
        if (input1Value == '' || input2Value == '') {
            Toast.show({
                icon: 'fail',
                content: t('t3'),
            })
        } else {
            Toast.show({
                icon: 'loading',
                content: t('t4'),
            })
            login({
                phone: `${areaNum}` + input1Value,
                password: input2Value,
            }).then(res => {
                if (res.code == 1) {
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('tokenTime', new Date().getTime())
                    Toast.show({
                        icon: 'success',
                        content: t('t5'),
                    })
                    setTimeout(() => {
                        navigate('/')
                    }, 1500);
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: res.info,
                    })
                }
            })
        }

    }
    return (
        <div className='login'>
            <div className='lang' onClick={() => { setVisible(true) }}>
                <p>{t('l1')}</p>
            </div>
            <div className='from_login'>
                <div className='logo'>
                    <img src="/assets/index/logo.svg" alt="" />
                </div>
                <div className='login_from'>
                    <div className='input_from'>
                        <div className='area_code' onClick={() => { navigate('/Area', { state: 1 }) }}>
                            <p> + {areaNum}</p>
                            <DownOutline />
                        </div>
                        <input className='account' type="text" onChange={(e) => setInput1Value(e.target.value)} placeholder={t('l2')} />
                    </div>
                    <div className='input_from'>
                        <input className='password' type="password" onChange={(e) => setInput2Value(e.target.value)} placeholder={t('l3')} />
                    </div>
                    <div className='Remember'>
                        <Checkbox style={{
                            '--icon-size': '18px',
                            '--font-size': '14px',
                            '--gap': '6px',
                        }}>{t('l4')}</Checkbox>
                    </div>
                    <div className='submit'>
                        <Button block color='' onClick={() => { submit() }}>
                            {t('l5')}
                        </Button>
                    </div>
                    <div style={{ height: '15px' }}></div>
                    <div className='login_text'>
                        <p>{t('l7')}?</p><p onClick={() => { navigate('/Register') }}> {t('l6')}</p>
                    </div>
                </div>
            </div>
            <Picker
                columns={list1}
                defaultValue={[localStorage.getItem('lang') || 'en']}
                cancelText={t('ty2')}
                confirmText={t('ty1')}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                onConfirm={v => {
                    i18n.changeLanguage(v[0])
                    localStorage.setItem('lang', v[0])
                }}
            />
        </div>
    )
}
