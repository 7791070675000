export const pt = {
    "n1": "primeira página",
    "n2": "Ordem",
    "n3": "troca",
    "n4": "equipe",
    "n5": "meu",
    "t1": "erro",
    "t2": "Replicação",
    "t3": "Por favor, preencha as informações completas",
    "t4": "carregando…",
    "t5": "Aterrissou com sucesso",
    "t6": "A pontuação precisa ser maior que 3 para enviar",
    "t7": "submetido com sucesso",
    "t8": "Equilíbrio insuficiente, incapaz de receber",
    "t9": "A submissão falhou",
    "t10": "Por favor, vincule o endereço primeiro, e a página do centro pessoal está prestes a pular",
    "t11": "Para o endereço de ligação, entre em contato com o atendimento ao cliente para modificar",
    "t12": "Com sucesso modificado",
    "t13": "não conseguir editar",
    "t14": "Entre em contato com o atendimento ao cliente para modificar a senha",
    "t15": "Erro de formato de senha",
    "t16": "Retirada bem -sucedida",
    "t17": "A maior parte da retirada",
    "t18": "Dinheiro mínimo",
    "t19": "Duas senhas são inconsistentes",
    "t20": "dica",
    "t21": "Não posso pular temporariamente",
    "t22": "Erro de formato de senha",
    "t23": "Registro bem sucedido",
    "t24": "Por favor, insira o código da área",
    "t25": "Não mais",
    "l1": "linguagem",
    "l2": "Insira o nome de usuário",
    "l3": "Por favor insira a senha",
    "l4": "lembrar",
    "l5": "Conecte-se",
    "l6": "Registro",
    "l7": "Sem conta",
    "l8": "Número de telefone",
    "l9": "Apelido",
    "l10": "senha",
    "l11": "Confirme sua senha",
    "l12": "Código de Convite",
    "l13": "registro",
    "l14": "Conta existente",
    "l15": "Conecte-se",
    "l16": "Digite a senha digital de 6 a 16 dígitos",
    "bz1": "ajuda",
    "bz2": "problema comum",
    "bz3": "Regras de negociação",
    "bz4": "Como retirar dinheiro",
    "bz5": "Como recarregar",
    "bz6": "1. O que é o robô de revisão TheEbay?",
    "bz7": "O eBay Review Robot é uma empresa de marketing que ajuda os comerciantes de lojas on -line do eBay a revisar o robô de todo o mundo para obter mais vendas de pedidos e aumentar os dados de visualização da loja on -line do eBay Review Robot. Estamos comprometidos em estabelecer um modelo de marketing de lucro de três partidos entre o eBay Review Robot e os comerciantes e os consumidores. Combinando a mais recente tecnologia Blockchain P2P, conecta rapidamente consumidores e comerciantes através do USDT (TRC20, ERC20). Os usuários registrados podem obter a Comissão de Pedidos, enquanto os comerciantes podem aumentar os dados de vendas da loja. É o modelo de lucro mais recente no modelo Blockchain da Internet!",
    "bz8": "2. Como trabalhar o robô de revisão de ForeBay?",
    "bz9": "Através do feedback diário do robô de revisão do eBay, os produtos que precisam ser aprimorados nas vendas de robôs do eBay Review, os usuários precisam apenas clicar facilmente no pedido para gerar automaticamente assinaturas de pedidos no sistema. Os usuários pagam o valor do pedido através do USDT da blockchain e recebem comissões diárias.",
    "bz10": "3. Por que há uma diferença no preço da mesma moeda nas duas transações?",
    "bz11": "As diferenças de preços são causadas por todos os fatores que não são propícios à liberdade de moeda, incluindo a velocidade de transferência de moeda, condições de rede, restrições de acesso à moeda, pessoas em diferentes regiões e até mesmo os tipos de negociação e transações fornecidas pela troca. Portanto, a mesma moeda pode causar diferenças de preços em diferentes transações.",
    "bz12": "4. Lucro do investimento?",
    "bz13": "Quanto maior o preço que você obtém, maior o lucro obtido. Ao mesmo tempo, o eBay Review Robot aloca aleatoriamente grandes ordens de comissão.",
    "bz14": "Todos os dias, o sistema gera e distribui automaticamente 60 pedidos de commodities ao usuário. Grandes ordens de comissão são distribuídas aleatoriamente.",
    "bz15": "O USDT pode ser retirado após 60 ordens. (Se os 60 pedidos não forem concluídos, o sistema parará automaticamente até o valor do excesso de pedido do dia)",
    "bz16": "Cada conta só pode estar vinculada a uma conta USDT.",
    "bz17": "Depois de concluir a entrega diária de pedidos, você pode se retirar normalmente.",
    "bz18": "Antes de recarregar, vincule seu endereço de retirada (suporte TRC-20) USDT.",
    'bz19': "É proibido realizar recargas mútuas, transferências bancárias, transferências de câmbio, etc. entre contas relacionadas. Este comportamento é suspeito de captura de ordem maliciosa. Se tal comportamento for detectado, a conta será banida.",
    "ty1": "Claro",
    "ty2": "Cancelar",
    "ty3": "enviar",
    "ty4": "endereço",
    "s1": "Renda cumulativa",
    "s2": "Servir",
    "s3": "sobre nós",
    "s4": "ajuda",
    "s5": "Parceiro",
    "s6": "Meu serviço",
    "d1": "desfeito",
    "d2": "concluído",
    "d3": "Sem dados",
    "d4": "Número único",
    "d5": "Hora da transação",
    "d6": "Quantia",
    "d7": "Número da tarefa atual",
    "d8": "Retorno esperado",
    "d9": "Ainda",
    "d10": "pontuação",
    "j1": "Saldo da conta",
    "j2": "Introdução à transação",
    "j3": "O eBay Review Robot usa o feedback do eBay Review Robot todos os dias para exibir os produtos que precisam ser aprimorados pelo eBay Review Robot Sales. Se o usuário precisar clicar apenas no pedido, o sistema gerará automaticamente uma assinatura de pedido. O usuário paga o valor do pedido através do Blockchain do USDT e pode obter mais de 0,6%da comissão de cada vez, e o sistema distribui aleatoriamente grandes ordens de recompensa.",
    "j4": "Comece a combinar",
    "j5": "Benefícios de hoje",
    "j6": "Número concluído",
    "j7": "Tarefa total",
    "td1": "Equilíbrio",
    "td2": "comissão",
    "td3": "receber",
    "td4": "Pessoas totais",
    "td5": "do utilizador",
    "td6": "contribuir",
    "td7": "quantidade",
    "td8": "estado",
    "g1": "retirar",
    "g2": "completar",
    "g3": "convide amigos",
    "g4": "Convite agora",
    "g5": "Para cada lucro obtido pelos amigos, você receberá a Comissão de Proporção Correspondente",
    "g6": "informações pessoais",
    "g7": "Detalhes de capital",
    "g8": "Recarregar o registro",
    "g9": "Recorde de retiradas",
    "g10": "linguagem",
    "g11": "sair",
    "g12": "Tem certeza de que sai e efetua login?",
    "yh1": "informação de usuário",
    "yh2": "Rever",
    "yh3": "configurar",
    "yh4": "Telefone",
    "yh6": "Senha Antiga",
    "yh7": "Nova Senha",
    "mx1": "Coleta de comissão",
    "mx2": "renda",
    "mx3": "Retirar",
    "mx4": "tempo",
    "mx5": "Recarrega",
    "mx6": "rejeitado",
    "mx7": "Pendente",
    "mx8": "Endereço de retirada",
    "mx9": "Saldo disponível",
    "mx10": "Na verdade",
    "mx11": "Por favor, insira o valor da retirada",
    "cz1": "O endereço de depósito suporta apenas o ERC20, o valor mínimo do depósito é 20USDT",
    "cz2": "cópia de",
    "cz3": "Seleção de moeda legal",
    "cz4": "Pagar",
    "cz5": "O endereço de depósito suporta apenas TRC20-USDT, e o valor mínimo do depósito é 20USDT",
    "cz6": "O endereço de depósito suporta apenas o ERC20, o valor mínimo do depósito é 20USDT",
    "cz10": "O endereço de depósito suporta apenas o BTC",
    "qy1": "Código Regional",
    "yq1": "convide amigos",
    "yq2": "Código de Convite",
    "yq3": "Convide amigos para desfrutar de uma grande renda",
    "yq4": "Vamos",
    "new1": "Forma de pagamento"
  }
  